import { Card, DatePicker, Spin } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { selectFilters, setFilters } from "../../../redux/DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useProcessedQuotesStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { AntDRangePresets } from "../../common/Constants";
import { DashboardStatsType, PricingRequestStatus } from "../../common/Enums";
import { localeCurrency, localeNumber, ChartColors } from "../../common/CommonFunctions";

type SeriesType = {
  name: string,
  data: number[]
}

type Props = {
  teamStats: boolean,
  isPriceChart?: boolean
}

const QuotesProcessedStats: React.FC<Props> = (props) => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<SeriesType[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<any>(null);
  const [triggerQuoteStats, quoteStatsResult] = useProcessedQuotesStatsMutation();
  const dispatch = useAppDispatch();
  const filtersKey = props.teamStats ? (props.isPriceChart ? DashboardStatsType.TeamQuotesValueProcessed : DashboardStatsType.TeamQuotesProcessed) :
  (props.isPriceChart ? DashboardStatsType.QuotesValueProcessed : DashboardStatsType.QuotesProcessed);
  const filters = useAppSelector(state => selectFilters(state, filtersKey));

  const onRangeChange = (range: any) => {
    if (range) {
      let toDate: dayjs.Dayjs = range[1];
      range[1] = toDate.endOf('M');
    }

    setDateRange(range);
    setMonthCategories(range);

    dispatch(setFilters({
      type: filtersKey,
      filters: {
        range: [range[0].format("MM/DD/YYYY"), range[1].format("MM/DD/YYYY")]
      }
    }));
  }

  const setMonthCategories = (dateRange: dayjs.Dayjs[]) => {
    let _categories = [], dateRanges = _.cloneDeep(dateRange);

    while (dateRanges[0].endOf('M') <= dateRanges[1].endOf('M')) {
      _categories.push(dateRanges[0].format('MMM YY'));
      dateRanges[0] = dateRanges[0].add(1, "M");
    };

    setCategories(_categories);
    return _categories;
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerQuoteStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null,
      teamStats: props.teamStats
    });
  }

  const initOptions = (chartSeries: SeriesType[]) => {

    setSeries(chartSeries);

    setOptions({
      colors: ChartColors,
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: categories.length === 1 ? '25%' : (categories.length <= 3 ? '45%' : '90%'),
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      stroke: {
        colors: ["transparent"],
        width: 3
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => {
          return props.isPriceChart ? localeCurrency(val) : localeNumber(val);
        },
        style: {
          fontSize: '12px',
          colors: ["#000"]
        }
      },
      xaxis: {
        categories: categories,
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        min: !props.isPriceChart ? 0 : undefined,
        max: !props.isPriceChart ? ((_.max([_.max(chartSeries[0].data), _.max(chartSeries[0].data)]) || 0) <= 3 ? 5 : undefined) : undefined,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: (val: number) => {
            return props.isPriceChart ? localeCurrency(val) : localeNumber(parseInt(val.toFixed(0)));
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false
      },
      fill: {
        opacity: 1
      },
      legend: {
        horizontalAlign: 'center',
        showForSingleSeries: true,
        position: 'bottom'
      }
    })
  }

  useEffect(() => {
    let dateRange = AntDRangePresets?.[0].value;

    if (filters?.range) {
      dateRange = [dayjs(filters.range[0]), dayjs(filters.range[1])];
    }

    onRangeChange(dateRange);

  }, []);

  useEffect(() => {
    if (filters && !dateRange)
      return;

    loadStats(dateRange);
  }, [categories]);

  useEffect(() => {
    if (quoteStatsResult.requestId && !quoteStatsResult.isLoading &&
      quoteStatsResult.data && quoteStatsResult.data.success) {

      const openQuotesDict: Record<string, number> = {},
        finalQuotesDict: Record<string, number> = {};

      let statsData = cloneDeep(quoteStatsResult.data.data || [])

      if (props.isPriceChart)
        _.remove(statsData, x => x.totalPrice <= 0);

      _.forEach(statsData, x => {
        // Set Completion Month display label
        let completionOn = dayjs(`${x.year}-${x.month}-01`, 'YYYY-M-D').format("MMM YY");

        if (x.status === PricingRequestStatus.Final) {
          // Set data grouping based on submission month
          finalQuotesDict[completionOn] = props.isPriceChart ? x.totalPrice : x.requestCount;
        }
        else {
          // Set data grouping based on submission month
          openQuotesDict[completionOn] = props.isPriceChart ? x.totalPrice : x.requestCount;
        }
      });

      let openQuoteSeries: SeriesType = { name: 'Active Quotes', data: [] },
        finalQuoteSeries: SeriesType = { name: 'Complete Quotes', data: [] };

      categories.forEach(x => {
        openQuoteSeries.data.push(openQuotesDict[x] ?? 0);
        finalQuoteSeries.data.push(finalQuotesDict[x] ?? 0);
      });

      initOptions([openQuoteSeries, finalQuoteSeries]);
    }
  }, [quoteStatsResult]);

  return (
    <Card className="h-100">
      <Spin spinning={quoteStatsResult.isLoading}>

        <div className='d-flex align-items-center justify-content-between mb-5'>
          <span className='fs-3 fw-bold text-dark me-2 lh-1 flex-grow-1'>
            {
              props.isPriceChart ? 'Quotes Processed ($)' : 'Quotes Processed'
            }
          </span>

          <DatePicker.RangePicker
            style={{ maxWidth: '250px' }}
            presets={AntDRangePresets}
            picker="month"
            size="small"
            value={dateRange}
            onChange={onRangeChange}
          />
        </div>

        {
          options !== null &&
          <ReactApexChart options={options}
            series={series}
            type="bar" height={'300'} width={'100%'} />
        }
      </Spin>
    </Card>
  )
}

export { QuotesProcessedStats };

