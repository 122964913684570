import { RTKQueryTags } from '../../app/common/Enums';
import { APIResponseModel, ActiveUserModel, CompanySetupConfig, MFAFactor, UserMetaDataModel } from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const UserApi = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserSelectList: builder.query<ActiveUserModel[], void>({
      query: () => ({
        url: '/user/active_list_select',
      }),
      transformResponse: (response: APIResponseModel<ActiveUserModel[]>) => response.data || [],
      providesTags: (_) => [RTKQueryTags.UserSelectList],
      keepUnusedDataFor: 60 * 60
    }),
    getSetupConfig: builder.query<CompanySetupConfig | null, void>({
      query: () => ({
        url: '/user/validate',
      }),
      transformResponse: (response: APIResponseModel<CompanySetupConfig>) => response.data,
      providesTags: (_) => [RTKQueryTags.CompanySetupConfig],
      keepUnusedDataFor: 60 * 60
    }),
    getMFAFactors: builder.query<MFAFactor[], {userId:string}>({
      query: (params) => ({
        url: `/user/mfa_factors/${params.userId}`,
      }),
      transformResponse: (response: APIResponseModel<MFAFactor[]>) => response.data || []
    }),
    deleteMFAFactor: builder.mutation<APIResponseModel<void>, {userId:string, factorId:string| null}>({
      query: (params) => ({
        url: `/user/mfa_factors/${params.userId}${(params.factorId ? `/${params.factorId}` : '')}`,
        method: "DELETE"
      })
    }),
    updateProfile: builder.mutation<APIResponseModel<void>, UserMetaDataModel>({
      query: (params) => ({
        url: '/user/profile',
        method: "POST",
        body: params
      })
    }),
    forgotPassword: builder.mutation<APIResponseModel<void>, {email: string}>({
      query: (params) => ({
        url: '/user/forgot_password',
        method: "POST",
        body: params
      })
    }),
  })
})

export const { 
  useGetUserSelectListQuery, useLazyGetSetupConfigQuery, useGetSetupConfigQuery,
  useGetMFAFactorsQuery, useDeleteMFAFactorMutation, useUpdateProfileMutation,
  useForgotPasswordMutation
} = UserApi