import { Factor } from "@supabase/supabase-js";
import { Button, Card, Divider, Form, Input, Modal, Popconfirm, Select, Spin } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useGetPricingInstructionSelectListQuery } from "../../../redux/rtkquery/PricingApi";
import { useGetUserSelectListQuery, useUpdateProfileMutation } from "../../../redux/rtkquery/UserApi";
import { AntdOptionSearchFn, HasAnyRole } from "../../common/CommonFunctions";
import AntSelect from "../../common/components/AntdSelect";
import { FactorTypes, Roles } from "../../common/Enums";
import { ActiveUserModel, UserMetaDataModel } from "../../models";
import { useAuth } from "../auth";
import { EnrollTOTP } from "../auth/components/EnrollTOTP";
import { refreshSession, supabase } from "../auth/core/supabaseClient";

const Profile: React.FC = (props) => {
    const { user_metadata, saveAuth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [enrollModalOpen, setEnrollModalOpen] = useState(false);
    const [factors, setFactors] = useState<Factor[]>([]);
    const [form] = Form.useForm();
    const [triggerUpdateProfile] = useUpdateProfileMutation();
    const allUserListState = useGetUserSelectListQuery();
    const allPricingInstructionSelectList = useGetPricingInstructionSelectListQuery();
    const [userProfile] = useState<any>({
        firstName: user_metadata?.firstName,
        lastName: user_metadata?.lastName,
        phoneNo: user_metadata?.phoneNo || "",
        defaultPriceBookId: user_metadata?.defaultPriceBookId,
        pricing_member_ids: user_metadata?.pricing_member_ids,
        pricing_approver_ids: user_metadata?.pricing_approver_ids,
        project_member_ids: user_metadata?.project_member_ids,
        project_approver_ids: user_metadata?.project_approver_ids
    });

    const onProfileFinishFailed = (errorInfo: any) => {
        //message.error("Please fix highlighted errors");
    }

    const onProfileFinish = (values: UserMetaDataModel) => {
        if (values.phoneNo && !isValidPhoneNumber(values.phoneNo)) {
            Modal.confirm({
                title: "Invalid Phone No. format",
                content: <>Phone No. {values.phoneNo} seems not valid. Are you sure to save it?</>,
                okText: "Yes",
                cancelText: "No",
                onOk: () => saveProfile(values)
            });
            return;
        }

        saveProfile(values);
    }

    const saveProfile = async (values: UserMetaDataModel) => {
        setLoading(true);

        let saveResponse = await triggerUpdateProfile(values);

        if ('data' in saveResponse) {
            let session = await refreshSession();
            session && saveAuth(session);

            setLoading(false);
            return;
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchFactors();
    }, []);

    const fetchFactors = () => {
        setLoading(true);

        supabase.auth.mfa.listFactors().then(list => {
            setFactors(list.data?.totp || []);
        }).finally(() => {
            setLoading(false);
        });
    }

    const remove2fa = async (id: string) => {
        const { data } = await supabase.auth.mfa.listFactors();
        let unverified_factor = data?.all.find(x => x.id === id && x.status === "unverified");

        if (unverified_factor || enableTwoFactor) {
            setLoading(true);
            supabase.auth.mfa.unenroll({ factorId: id }).then(response => {
                if (!response.error) {
                    fetchFactors();
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const onEnrolled = () => {
        fetchFactors();
        setEnrollModalOpen(false);
    }

    const onEnrollCancelled = (enrollId?: string) => {
        if (enrollId)
            remove2fa(enrollId);

        setEnrollModalOpen(false);
    }

    const enableTwoFactor = useMemo(() => {
        return user_metadata?.is2fa || user_metadata?.c_is2fa ? true : false;
    }, [factors]);

    return (<>
        <Spin spinning={loading}>
            <>
                <Card title="Profile" className="mb-5">
                    <Form
                        layout="vertical"
                        form={form}
                        name="AddEditUser"
                        initialValues={userProfile}
                        onFinish={onProfileFinish}
                        onFinishFailed={onProfileFinishFailed}
                        autoComplete="off"
                    >
                        <Row>
                            <Col md={6}>
                                <Form.Item name="firstName" label="First Name"
                                    rules={[{ required: true, message: "'${label}' is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={6}>
                                <Form.Item name="lastName" label="Last Name"
                                    rules={[{ required: true, message: "'${label}' is required" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Form.Item name="phoneNo" label="Phone">
                                    <PhoneInput international
                                        defaultCountry="US"
                                        placeholder="Enter phone number"
                                        onChange={() => { }} // adding as required by component
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {
                            HasAnyRole(user_metadata?.roles ?? [], [Roles.CompanyAdmin, Roles.ProjectOwner]) &&
                            <>
                                <Divider orientation="left" orientationMargin={0} className="border-secondary">Project Default Settings</Divider>

                                <Row>
                                    <Col md={6}>
                                        <Form.Item name="project_member_ids" label="Team Members">
                                            <AntSelect showSearch
                                                mode="multiple"
                                                loading={allUserListState.isFetching}
                                                options={allUserListState.data || []}
                                                filterOption={AntdOptionSearchFn}
                                                placeholder="Select Team members"
                                                fieldNames={{ label: "fullName", value: "userId" }}
                                                isValid={(user: ActiveUserModel) => {
                                                    // Keep current user but disabled
                                                    if (user.userId === user_metadata?.userId)
                                                        return null;
                                                    if (!user.isActive)
                                                        return "Inactive";
                                                    if (!HasAnyRole(user.roles, [Roles.CompanyAdmin, Roles.ProjectOwner, Roles.Collaborator]))
                                                        return "Lacks required permissions";
                                                    return true;
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Item name="project_approver_ids" label="Approvers">
                                            <AntSelect
                                                mode="multiple"
                                                loading={allUserListState.isFetching}
                                                options={allUserListState.data || []}
                                                filterOption={AntdOptionSearchFn}
                                                placeholder="Select Approvers"
                                                fieldNames={{ label: "fullName", value: "userId" }}
                                                isValid={(user: ActiveUserModel) => {
                                                    if (!user.isActive)
                                                        return "Inactive";
                                                    if (!HasAnyRole(user.roles, [Roles.CompanyAdmin, Roles.ProjectOwner]))
                                                        return "Lacks required permissions";
                                                    return true;
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        }

                        {
                            HasAnyRole(user_metadata?.roles ?? [], [Roles.PricingAdmin, Roles.PricingMember]) &&
                            <>
                                <Divider orientation="left" orientationMargin={0} className="border-secondary">Quote Default Settings</Divider>

                                <Row>
                                    <Col md={6}>
                                        <Form.Item name="pricing_member_ids" label="Team Members">
                                            <AntSelect showSearch
                                                mode="multiple"
                                                loading={allUserListState.isFetching}
                                                options={allUserListState.data || []}
                                                filterOption={AntdOptionSearchFn}
                                                placeholder="Select Team members"
                                                fieldNames={{ label: "fullName", value: "userId" }}
                                                isValid={(user: ActiveUserModel) => {
                                                    // Keep current user but disabled
                                                    if (user.userId === user_metadata?.userId)
                                                        return null;
                                                    if (!user.isActive)
                                                        return "Inactive";
                                                    if (!HasAnyRole(user.roles, [Roles.PricingAdmin, Roles.PricingMember]))
                                                        return "Lacks required permissions";
                                                    return true;
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Item name="pricing_approver_ids" label="Approvers">
                                            <AntSelect showSearch
                                                mode="multiple"
                                                loading={allUserListState.isFetching}
                                                options={allUserListState.data || []}
                                                filterOption={AntdOptionSearchFn}
                                                placeholder="Select Approvers"
                                                fieldNames={{ label: "fullName", value: "userId" }}
                                                isValid={(user: ActiveUserModel) => {
                                                    if (!user.isActive)
                                                        return "Inactive";
                                                    if (!HasAnyRole(user.roles, [Roles.PricingAdmin, Roles.PricingMember]))
                                                        return "Lacks required permissions";
                                                    return true;
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Item name="defaultPriceBookId" label="Price Book" className="mb-0">
                                            <Select
                                                placeholder="Select Price Book"
                                                options={allPricingInstructionSelectList.data || []}
                                                fieldNames={{ label: "instructionName", value: "id" }}
                                                loading={allPricingInstructionSelectList.isFetching}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        }

                        <div className="text-end mt-8 pt-5 border-top">
                            <Button size="large" type="primary" htmlType="submit">Save Changes</Button>
                        </div>
                    </Form>
                </Card>

                <Card title='2FA Setup'
                    extra={
                        enableTwoFactor &&
                        <Button type="primary" ghost onClick={() => setEnrollModalOpen(true)}>
                            Configure New
                        </Button>
                    }
                >
                    <table className="table table-striped">
                        <thead>
                            <tr className="fw-semibold">
                                <th>TYPE</th>
                                <th>STATUS</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(factors, x => (
                                    <tr key={x.id}>
                                        <td>
                                            <span>{(FactorTypes as any)[x.factor_type].value}</span>
                                            {
                                                _.size(x.friendly_name || '') > 0 ?
                                                    <div className="text-muted mt-2">{x.friendly_name}</div> : null
                                            }
                                        </td>
                                        <td>{x.status.toUpperCase()}</td>
                                        <td>
                                            <Popconfirm
                                                title="Remove Factor"
                                                description="Are you sure to remove this factor?"
                                                onConfirm={() => remove2fa(x.id)}
                                                okText="Yes"
                                                cancelText="No"
                                                disabled={!enableTwoFactor}
                                            >
                                                <Button size='small' danger ghost
                                                    disabled={!enableTwoFactor}>
                                                    Remove
                                                </Button>
                                            </Popconfirm>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Card>
            </>
        </Spin>

        <Modal open={enrollModalOpen}
            footer={null}
            width={800}
            destroyOnClose={true}
            onCancel={() => onEnrollCancelled()}>
            <EnrollTOTP onCancelled={onEnrollCancelled} onEnrolled={onEnrolled} />
        </Modal>
    </>
    )
}

export { Profile };

