import {
  APIResponseModel,
  AnswerAutomationStatModel,
  AnswerAutomationTrendStatModel,
  DashboardOpenQuoteStats,
  DashboardQuoteStats,
  DashboardStatsFilterModel,
  MyTaskStatsModel,
  PricingRequestModel,
  ProjectCompletedStatsModel,
  ProjectListItemModel,
  ProjectListStatsModel,
  ProjectStatusStatModel
} from '../../app/models';
import { RTKBaseApi } from './BaseApi';

const DashboardAPI = RTKBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    AnswerAutomationStats: builder.mutation<APIResponseModel<AnswerAutomationStatModel[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/question_automation_stats',
        method: "POST",
        body: params
      })
    }),
    AnswerAutomationTrendStats: builder.mutation<APIResponseModel<AnswerAutomationTrendStatModel[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/question_automation_trend_stats',
        method: "POST",
        body: params
      })
    }),
    ProjectStatusStats: builder.mutation<APIResponseModel<ProjectStatusStatModel[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/project_status_stats',
        method: "POST",
        body: params
      })
    }),
    ProjectCompletedStats: builder.mutation<APIResponseModel<ProjectCompletedStatsModel[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/project_completed_stats',
        method: "POST",
        body: params
      })
    }),
    MyTaskStats: builder.mutation<APIResponseModel<MyTaskStatsModel>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/my_task_stats',
        method: "POST",
        body: params
      })
    }),
    recentProjects: builder.mutation<APIResponseModel<ProjectListItemModel[]>, { limit: number }>({
      query: (params) => ({
        url: '/dashboard/recent_projects',
        method: "POST",
        body: params
      })
    }),
    projectStats: builder.mutation<APIResponseModel<ProjectListStatsModel[]>, { limit: number, dueDate: string }>({
      query: (params) => ({
        url: '/dashboard/project_list_stats',
        method: "POST",
        body: params
      })
    }),

    quotesStats: builder.mutation<APIResponseModel<DashboardOpenQuoteStats[]>, { teamStats?: boolean }>({
      query: (params) => ({
        url: '/dashboard/quotes_stats',
        method: "POST",
        body: params
      })
    }),
    OpenQuotesStats: builder.mutation<APIResponseModel<DashboardOpenQuoteStats>, { teamStats?: boolean }>({
      query: (params) => ({
        url: '/dashboard/open_quotes_stats',
        method: "POST",
        body: params
      })
    }),
    QuotesForApprovalStats: builder.mutation<APIResponseModel<DashboardOpenQuoteStats>, { teamStats?: boolean }>({
      query: (params) => ({
        url: '/dashboard/quotes_for_approval_stats',
        method: "POST",
        body: params
      })
    }),
    RecentQuotes: builder.mutation<APIResponseModel<PricingRequestModel[]>, { limit: number }>({
      query: (params) => ({
        url: '/dashboard/recent_quotes',
        method: "POST",
        body: params
      })
    }),
    OwnerQuotesStats: builder.mutation<APIResponseModel<DashboardQuoteStats[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/owner_quotes_stats',
        method: "POST",
        body: params
      })
    }),
    ApproverQuotesStats: builder.mutation<APIResponseModel<DashboardQuoteStats[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/approver_quotes_stats',
        method: "POST",
        body: params
      })
    }),
    ProcessedQuotesStats: builder.mutation<APIResponseModel<DashboardQuoteStats[]>, DashboardStatsFilterModel>({
      query: (params) => ({
        url: '/dashboard/processed_quotes_stats',
        method: "POST",
        body: params
      })
    }),
  })
})

export const {
  useAnswerAutomationStatsMutation,
  useAnswerAutomationTrendStatsMutation,
  useProjectStatusStatsMutation,
  useProjectCompletedStatsMutation,
  useMyTaskStatsMutation,
  useRecentProjectsMutation,
  useProjectStatsMutation,
  useQuotesStatsMutation,
  useOpenQuotesStatsMutation,
  useQuotesForApprovalStatsMutation,
  useRecentQuotesMutation,
  useOwnerQuotesStatsMutation,
  useApproverQuotesStatsMutation,
  useProcessedQuotesStatsMutation
} = DashboardAPI