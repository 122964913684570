import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import _ from "lodash";
import { useState } from "react";
import { ActiveUserModel, DataSetSelectModel, SystemSettingModel } from "../models";
import { CONST_DATE_DB_FORMAT, CONST_DATETIME_DB_FORMAT, CONST_DATETIME_UI_FORMAT } from "./Constants";
import { DataSetType, ExportTemplateType, FreshnessScoreType, Roles } from "./Enums";

export const IsSuperAdmin = (roles?: number[]) => roles?.some(x => x === Roles.SuperAdmin) ?? false;
export const IsCompanyAdmin = (roles?: number[]) => roles?.some(x => x === Roles.CompanyAdmin) ?? false;
export const IsProjectOwner = (roles?: number[]) => roles?.some(x => x === Roles.ProjectOwner) ?? false;
export const IsCollaborator = (roles?: number[]) => roles?.some(x => x === Roles.Collaborator) ?? false;
export const IsPricingAdmin = (roles?: number[]) => roles?.some(x => x === Roles.PricingAdmin) ?? false;
export const IsPricingMember = (roles?: number[]) => roles?.some(x => x === Roles.PricingMember) ?? false;
export const HasAnyRole = (userRoles: number[], roleList: Roles[]) => _.intersection(userRoles, roleList).length > 0;
export const IsCollaboratorOnly = (roles?: number[]) => roles && roles.length === 1 && roles[0] === Roles.Collaborator;
export const AntdSystemSettingsSelectSearchFn = (input: string, option: SystemSettingModel | undefined) => (option?.name?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
export const AntdOptionSearchFn = (input: string, option: DefaultOptionType | undefined) => ((option?.labelString || option?.label)?.toString().toLowerCase() ?? '').includes(input.toLowerCase());
export const ChartColors = ['#5c6bc0', '#8c9eff', '#7986cb', '#d1c4e9', '#81d4fa', '#00e5ff', '#80deea', '#bbdefb', '#303f9f', '#3f51b5', '#039be5', '#00acc1', '#00897b', '#66bb6a', '#9ccc65', '#ffe082', '#ffcc80', '#ffab91', '#bcaaa4', '#b0bec5', '#4fc3f7', '#80cbc4', '#00b0ff', '#00bfa5', '#ffab40'];


export function useStateWithCallback<T>(initialState: T): [T, (newState: T, callback?: (newState: T) => void) => void] {
    const [value, setValue] = useState(initialState);

    const setValueAndCallback = (newValue: T, callback?: (newValue: T) => void) => {
        setValue((prevValue: T) => {
            callback?.(newValue);
            return newValue;
        });
    };

    return [value, setValueAndCallback];
}

export function toSeoUrl(url: string) {
    return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-and-')          // Replace ampersand
        .replace(/[^a-z0-9\-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
}

export function getFormattedDate(date: string, outputFormat?: string, inputFormat?: string) {
    if (date)
        return dayjs(date, inputFormat ?? [CONST_DATETIME_DB_FORMAT, CONST_DATE_DB_FORMAT]).format(outputFormat ?? CONST_DATETIME_UI_FORMAT);

    return date;
}

export function downloadBlobSilently(fileBlob: Blob | MediaSource, filename: string) {
    let url = window.URL || window.webkitURL;
    var blobUrl = url.createObjectURL(fileBlob);
    downloadFileSilently(blobUrl, filename);
}

export function downloadFileSilently(href: string, filename: string) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = href;
    hiddenElement.style.display = "none";
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    document.body.appendChild(hiddenElement);
    hiddenElement.click();
    setTimeout(() => {
        document.body.removeChild(hiddenElement);
        let url = window.URL || window.webkitURL;
        url.revokeObjectURL(href);
    }, 2000);
}

export function sanitizeAndLimitFileName(fileName: string, maxLength: number = 256) {
    // Split the file name into base name and extension
    const parts = fileName.split('.');
    let baseFileName, fileExtension;
    if (parts.length > 1) {
        // If the file name has an extension, extract it
        fileExtension = parts.pop();
        baseFileName = parts.join('.');
    } else {
        // If the file name doesn't have an extension, treat it as the base name
        baseFileName = fileName;
        fileExtension = '';
    }
    // Sanitize and limit the base file name
    const sanitizedBaseFileName = baseFileName.replace(/[^\w.-]/g, '_').slice(0, maxLength);
    // Reassemble the sanitized file name with extension if it exists
    return fileExtension ? `${sanitizedBaseFileName}.${fileExtension}` : sanitizedBaseFileName;
}

export async function getExportWordTemplate(type: ExportTemplateType): Promise<Blob> {

    let templateName = "";

    switch (type) {
        case ExportTemplateType.Project:
            templateName = process.env.REACT_APP_WORD_EXPORT_TEMPLATE || "";
            break;
        case ExportTemplateType.PricingFinalPrice:
            templateName = process.env.REACT_APP_FINALPRICE_WORD_EXPORT_TEMPLATE || "";
            break;
    }

    const request = await fetch(templateName);
    const template = await request.blob();
    return template;
}

export function getDatasetGroups(datasets: DataSetSelectModel[]) {
    let optionGroups = {
        '0': { option: null } as any,
        [DataSetType.CompanyInfo.toString()]: { option: null },
        [DataSetType.Products.toString()]: { options: [], groupName: "Products" },
        [DataSetType.AdditionalDataSets.toString()]: { options: [], groupName: "Additional Datasets" }
    }

    _.forEach(datasets, x => {
        let group = optionGroups[x.datasetTypeId];

        let option = {
            title: x.datasetName,
            label: x.datasetName,
            value: x.datasetId,
        }

        if (group?.options) {
            group.options.push(option)
        }
        else {
            group.option = option
        }
    })

    const options: any[] = [];

    _.forOwn(optionGroups, (group, k) => {

        if (group.option) {
            options.push(group.option);
        }
        else if (group.options && group.options.length) {
            options.push({
                label: group.groupName,
                title: group.groupName,
                key: group.groupName,
                options: _.orderBy(group.options, o => o.label)
            });
        }
    })

    return options;
}

export function getActiveInactiveUserGroups(users: ActiveUserModel[], isInclude: (user: ActiveUserModel) => boolean) {
    let optionGroups = {
        available: { options: [] as ActiveUserModel[], groupName: "Available" },
        notAvailable: { options: [] as ActiveUserModel[], groupName: "Not Available" }
    }

    _.forEach(users, x => {
        if (isInclude(x))
            optionGroups.available.options.push(x);
        else
            optionGroups.notAvailable.options.push(x);
    })

    const options: any[] = [];

    _.forOwn(optionGroups, (group, k) => {
        if (group.options.length) {
            options.push({
                label: group.groupName,
                title: group.groupName,
                key: group.groupName,
                options: _.orderBy(group.options, o => o.fullName)
            });
        }
    })

    return options;
}

export const filterOptions = (search: string, optionOrGroup: any) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
        return false;
    }
    return optionOrGroup.label.toLowerCase().includes(search.toLowerCase());
};

export const getShortDisplayName = (firstName: string, lastName?: string) => {
    firstName = firstName || "--";
    let firstNameArr = firstName.split(' ');
    let shortName = lastName ? `${firstName[0]}${lastName[0]}` :
        `${firstNameArr[0][0]}${firstNameArr[1] ? firstNameArr[1][0] : firstName[1]}`;

    return shortName.toUpperCase();
}

export function processLaTex(content: string): string {

    return content.replaceAll("\n\\[ \n", "\n\`\`\`\n")
        .replaceAll("\n\\[", "\n\`\`\`\n")
        .replaceAll("\\[", "\`\`\`")
        .replaceAll("\n\\]\n", "\n\`\`\`\n")
        .replaceAll("\\]\n\n", "\n\`\`\`\n")
        .replaceAll("\\]", "\`\`\`")
        .replaceAll("\\(", "\`\`\`")
        .replaceAll("\\)", "\`\`\`");
}

export function localeNumber(value: number, culture = "en-US") {
    const formatter = new Intl.NumberFormat(culture);
    const formattedNumber = formatter.format(value);
    return formattedNumber;
}

export function localeCurrency(value: number, culture = "en-US", currency = "USD", keepFraction = false) {
    let options: any = {
        style: 'currency',
        currency
    }

    if (!keepFraction) {
        options.minimumFractionDigits = 0;
        options.maximumFractionDigits = 0;
    }

    const formatter = new Intl.NumberFormat(culture, options);
    const formattedCurrency = formatter.format(value);
    return formattedCurrency;
}

export const getFreshnessScoreColor = (score: number): string => {
    let color = 'gray';

    switch (score) {
        case FreshnessScoreType.Green:
            color = 'success';
            break;
        case FreshnessScoreType.Yellow:
            color = 'warning';
            break;
        case FreshnessScoreType.Red:
            color = 'danger';
            break;
    }

    return color;

}

export const getFreshnessScoreValue = (score: number): string => {
    let value = "N/A";

    switch (score) {
        case FreshnessScoreType.Green:
            value = 'High';
            break;
        case FreshnessScoreType.Yellow:
            value = 'Medium';
            break;
        case FreshnessScoreType.Red:
            value = 'Low';
            break;
    }

    return value;
}