import { GridOptions, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Card } from "antd";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { useRecentQuotesMutation } from "../../../redux/rtkquery/DashboardAPI";
import { PricingRequestModel } from "../../models";

const RecentQuotes: React.FC = () => {
  const navigate = useNavigate();
  const [triggerRecentQuotes] = useRecentQuotesMutation();

  const quotesGridOptions: GridOptions<PricingRequestModel> = {
    defaultColDef: {
      sortable: true,
      filter: false,
      resizable: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      autoHeight: true,
      suppressMovable: true,
      suppressHeaderMenuButton: true,
      suppressAutoSize: true,
      onCellClicked: (params) => {
        navigate(`/quotes/${params.data?.pricingRequestId}`)
      },
    },
    columnDefs:
      [
        {
          field: 'requestName',
          headerName: 'Name',
          flex: 1,
          cellRenderer: (params: ICellRendererParams<PricingRequestModel>) =>
            <div className="lh-1 py-2">
              <div className="fw-bold pb-2">
                {params.value}
              </div>
              <div className="fs-8 text-muted">Updated on {dayjs(params.data?.modifiedDate).format("DD MMM YY")}</div>
            </div>
        },
        {
          field: 'customerName',
          headerName: 'Customer',
          width: 150,
          cellClass: "d-flex-v-center",
          valueFormatter: (params) => params.value ?? "--"
        }
      ],
    rowData: null,
    rowHeight: 25,
    headerHeight: 25,
    onGridReady: (params) => {
      triggerRecentQuotes({ limit: 10 })
        .then((response) => {
          let rowData = 'data' in response ? response.data.data ?? [] : [];
          params.api.updateGridOptions({ rowData });
        })
        .finally(() => {
          params.api.updateGridOptions({ loading: false });
        })
    }
  }

  return (
    <Card size="small" bordered={true}
      title={<span className="fs-4">Recent Quotes</span>}>
      <div className="ag-theme-alpine ag-theme-alpine-custom w-100" style={{height: '271px'}}>
        <AgGridReact gridOptions={quotesGridOptions} />
      </div>
    </Card>
  )
}

export { RecentQuotes };

