import { DeleteOutlined, EditOutlined, FileProtectOutlined, MergeCellsOutlined, MoreOutlined, UserAddOutlined } from '@ant-design/icons';
import { App, Button, Card, Checkbox, Dropdown, Form, Input, MenuProps, Modal, Popover, Select, Spin, Tooltip } from "antd";
import _, { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { assignQuestions, deleteSection, mergeSections, selectProjectSettings, selectSectionStats, selectSectionsSelectList, updateSection } from '../../../redux/ProjectsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useAssignQuestionsMutation } from '../../../redux/rtkquery/QuestionApi';
import { useDeleteSectionMutation, useMergeSectionsMutation, useUpdateSectionMutation } from '../../../redux/rtkquery/SectionApi';
import { SectionModel, SectionSelectModel } from "../../models";
import { AssignQuestionRenderer } from './QuestionRenderer';

type TabPaneProps = {
    section: SectionModel,
    selected: boolean
}

const SectionRenderer: React.FC<TabPaneProps> = (props) => {

    const { section, selected } = props;
    const dispatch = useAppDispatch();
    const [triggerAssignQuestions, assignQuestionsResult] = useAssignQuestionsMutation();
    const [triggerDeleteSection, deleteSectionResult] = useDeleteSectionMutation();
    const [isApproverOpen, setIsApproverOpen] = useState(false);
    const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
    const [isMergeOpen, setIsMergeOpen] = useState(false);
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const sectionStats = useAppSelector(state => selectSectionStats(state, section.sectionId));
    const projectSettings = useAppSelector(state => selectProjectSettings(state));
    const { modal } = App.useApp();

    const items: MenuProps['items'] = [
        {
            label: 'Edit Section',
            key: 'edit',
            icon: <EditOutlined rev={0} />,
        },
        {
            label: 'Delete Section',
            key: 'delete',
            icon: <DeleteOutlined rev={0} />,
            danger: true
        }
    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            case 'edit':
                toggleUpdate();
                break;
            case 'delete':
                onDelete();
                break;
        }
    }

    const menuProps = {
        items,
        onClick: handleMenuClick,
    }

    const onSaveApprovers = (userIds: number[], replaceExisting?: boolean) => {

        triggerAssignQuestions({
            assignType: 1,
            projectId: props.section.projectId,
            sectionId: section.sectionId,
            userIds: userIds,
            replaceExisting: replaceExisting || false,
            questionIds: null
        }).then(response => {

            setIsApproverOpen(false);

            if ("error" in response) {
                // log/show error
                return;
            }

            dispatch(assignQuestions({
                projectId: section.projectId,
                sectionId: section.sectionId,
                userIds,
                replaceExisting: replaceExisting || false,
                assignType: 1
            }));
        });
    }

    const onSaveAssignees = (userIds: number[], replaceExisting?: boolean) => {

        triggerAssignQuestions({
            assignType: 0,
            projectId: props.section.projectId,
            sectionId: props.section.sectionId,
            userIds: userIds,
            replaceExisting: replaceExisting || false,
            questionIds: null
        }).then(response => {

            setIsAssigneeOpen(false);

            if ("error" in response) {
                // log/show error
                return;
            }

            dispatch(assignQuestions({
                projectId: section.projectId,
                sectionId: section.sectionId,
                userIds,
                replaceExisting: replaceExisting || false,
                assignType: 0
            }));
        });
    }

    const onDelete = () => {
        modal.confirm({
            title: 'Confirm deletion',
            content: <>
                <p>Are you sure you would like to delete section "{section.sectionName}"?</p>
                <p>All questions and responses for this section will be deleted.</p>
                <p className='text-danger fw-bold'>This action cannot be undone.</p>
            </>,
            okText: "Delete",
            okButtonProps: { danger: true },
            onOk: () => {
                triggerDeleteSection({
                    projectId: props.section.projectId,
                    sectionId: section.sectionId
                });
            }
        })
    }

    const toggleMerge = () => {
        setIsMergeOpen(!isMergeOpen);
    }

    const toggleUpdate = () => {
        setIsUpdateOpen(!isUpdateOpen);
    }

    useEffect(() => {
        if (deleteSectionResult.requestId && !deleteSectionResult.isLoading) {
            if (deleteSectionResult.data?.success) {
                dispatch(deleteSection(section));
            }
        }

    }, [deleteSectionResult]);

    return (<>
        <Card key={section.sectionId}
            size='small'
            className={'section-item shadow-sm ' + (selected ? 'active' : '')}
            actions={
                projectSettings?.isProjectOpen && projectSettings.hasFullAccess ?
                    [
                        <Tooltip title="Merge Sections">
                            <Button shape="circle" icon={<MergeCellsOutlined rev={undefined} />} onClick={toggleMerge} />
                        </Tooltip>,
                        <Tooltip title="Assign Questions">
                            <Popover
                                destroyTooltipOnHide
                                placement="topLeft"
                                open={isAssigneeOpen}
                                content={
                                    <AssignQuestionRenderer showReplaceExisting={true}
                                        userList={projectSettings.teamMembers}
                                        onSave={onSaveAssignees}
                                        loading={assignQuestionsResult.isLoading}
                                    />
                                }
                                title="Assign Question"
                                trigger="click"
                                onOpenChange={(visible) => setIsAssigneeOpen(visible && section.filteredQuestionIds.length > 0)}
                            >
                                <Button shape="circle"
                                    icon={<UserAddOutlined rev={undefined} />}
                                    disabled={section.filteredQuestionIds.length === 0} />
                            </Popover>
                        </Tooltip>,
                        <Tooltip title="Assign Approvers">
                            <Popover
                                destroyTooltipOnHide
                                placement="topLeft"
                                open={isApproverOpen}
                                popupVisible={section.filteredQuestionIds.length > 0}
                                content={
                                    <AssignQuestionRenderer showReplaceExisting={true}
                                        userList={projectSettings.approvers}
                                        onSave={onSaveApprovers}
                                        loading={assignQuestionsResult.isLoading}
                                    />
                                }
                                title="Assign Approvers"
                                trigger="click"
                                onOpenChange={(visible) => setIsApproverOpen(visible && section.filteredQuestionIds.length > 0)}
                            >
                                <Button shape="circle"
                                    icon={<FileProtectOutlined rev={undefined} />}
                                    disabled={section.filteredQuestionIds.length === 0} />
                            </Popover>

                        </Tooltip>,
                        <Tooltip title="Actions">
                            <Dropdown menu={menuProps} trigger={['click']}>
                                <Button shape="circle" icon={<MoreOutlined rev={0} />} />
                            </Dropdown>
                        </Tooltip>
                    ] : undefined}
        >
            <div className="d-flex">
                <div className="fs-3 fw-bold mb-3 flex-grow-1">{section.sectionName}</div>
                {/* {
                    projectSettings.isProjectOpen && projectSettings.hasFullAccess &&
                    <span className="ms-1 cursor-move"> <SortableTabDragHandle /></span>
                } */}
            </div>

            <div className="d-flex flex-wrap">
                <div className="border border-gray-300 border-dashed rounded min-w-50px py-1 px-2 me-2 text-center">
                    <div className="fs-8 fw-bold">{sectionStats.totalQuestion}</div>
                    <div className="fs-8">Questions</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-50px py-1 px-2 me-2 text-center">
                    <div className="fs-8 fw-bold">{sectionStats.totalSubmitted}</div>
                    <div className="fs-8">Submitted</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded min-w-50px py-1 px-2 me-2 text-center">
                    <div className="fs-8 fw-bold">{sectionStats.totalAccepted}</div>
                    <div className="fs-8">Accepted</div>
                </div>
                {
                    sectionStats.totalUnAnswered > 0 &&
                    <div className="border border-warning text-warning border-dashed rounded min-w-50px py-1 px-2 text-center">
                        <div className="fs-8 fw-bold">{sectionStats.totalUnAnswered}</div>
                        <div className="fs-8">Unanswered</div>
                    </div>
                }
            </div>

            {/* <Progress className='mt-4 progressbar' percent={30} size="small" /> */}
        </Card>
        {
            isMergeOpen && <MergeSection section={section} onClose={toggleMerge} />
        }
        {
            isUpdateOpen && <UpdateSection section={section} onClose={toggleUpdate} />
        }
    </>
    )
}

export { SectionRenderer };

const MergeSection: React.FC<{ section: SectionModel, onClose: () => void }> = (props) => {
    const [form] = Form.useForm();
    const sectionsSelectList = useAppSelector(state => selectSectionsSelectList(state, props.section.projectId));
    const [triggerMergeSections, mergeSectionsResult] = useMergeSectionsMutation();
    const dispatch = useAppDispatch();
    const [selectedSection, setSelectedSection] = useState<SectionSelectModel | null>(null);

    const onFinish = (values: any) => {
        triggerMergeSections({
            projectId: props.section.projectId,
            oldSectionId: props.section.sectionId,
            newSectionId: values.newSectionId
        })
    }

    const onSelect = (value?: number) => {
        if (value)
            setSelectedSection(sectionsSelectList.find(x => x.sectionId === value) || null);
        else
            setSelectedSection(null);
    }

    useEffect(() => {
        if (mergeSectionsResult.requestId && !mergeSectionsResult.isLoading) {
            if (mergeSectionsResult.data?.success) {
                let data = mergeSectionsResult.data.data;
                data && dispatch(mergeSections(data));

                props.onClose();
            }
        }

    }, [mergeSectionsResult]);

    return (
        <Modal title="Merge Section"
            open={true}
            maskClosable={false}
            keyboard={false}
            closable={false}
            width={600}
            destroyOnClose={true}
            onCancel={(e) => props.onClose()}
            okText="Merge"
            onOk={() => form.submit()}
        >
            <Spin spinning={mergeSectionsResult.isLoading}>
                <Form
                    className='my-10'
                    layout="vertical"
                    form={form}
                    name="MergeSection"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item name="newSectionId" label={<span>Merge <span className='fw-bold'>{props.section.sectionName}</span> into</span>}
                        rules={[{ required: true, message: "'Section' selection is required" }]}>
                        <Select className='mb-3'
                            placeholder="Select Section"
                            onSelect={onSelect}
                        >
                            {
                                _.map(sectionsSelectList, x =>
                                    <Select.Option key={x.sectionId}
                                        value={x.sectionId}
                                        disabled={x.sectionId === props.section.sectionId}>
                                        {x.sectionName}
                                    </Select.Option>
                                )
                            }
                        </Select>
                        <div className="fs-7 fw-semibold text-muted">
                            All questions will be moved in selected section and '{props.section.sectionName}' will be deleted.
                        </div>
                        {
                            (props.section.exportInOriginalFormat === true || selectedSection?.exportInOriginalFormat === true) &&
                            <div className="fs-7 fw-semibold text-danger mt-2">
                                Note: This section can not be exported in the original import format after the merge.
                            </div>
                        }
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}

const UpdateSection: React.FC<{ section: SectionModel, onClose: () => void }> = (props) => {
    const [form] = Form.useForm();
    const [triggerUpdateSection, updateSectionResult] = useUpdateSectionMutation();
    const dispatch = useAppDispatch();

    const onFinish = (values: any) => {
        triggerUpdateSection({
            projectId: props.section.projectId,
            sectionId: props.section.sectionId,
            sectionName: values.sectionName,
            exportInOriginalFormat: props.section.exportInOriginalFormat ? values.exportInOriginalFormat : props.section.exportInOriginalFormat
        })
    }

    // Added event due to space not working issue
    const onKeyDown = (e: any) => {
        if (e.key === " " || e.code === "Space")
            e.stopPropagation()
    }

    useEffect(() => {

        if (updateSectionResult.requestId && !updateSectionResult.isLoading) {
            if (updateSectionResult.data?.success) {
                let args = updateSectionResult.originalArgs;

                if (args) {
                    let section = cloneDeep(props.section);
                    section.sectionName = args.sectionName;
                    section.exportInOriginalFormat = args.exportInOriginalFormat;

                    dispatch(updateSection(section));
                }
                props.onClose();
            }
        }

    }, [updateSectionResult]);

    return (
        <Modal title="Update Section"
            open={true}
            maskClosable={false}
            keyboard={false}
            closable={false}
            width={600}
            destroyOnClose={true}
            onCancel={(e) => props.onClose()}
            okText="Update"
            onOk={form.submit}
            confirmLoading={updateSectionResult.isLoading}
        >
            <Spin spinning={updateSectionResult.isLoading}>
                <Form
                    className='my-10'
                    layout="vertical"
                    form={form}
                    name="UpdateSection"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={props.section}
                >
                    <Form.Item name="sectionName" label="Section Name"
                        rules={[
                            { required: true, message: "'${label}' is required" },
                            { max: 200, message: "'${label}' cannot be longer than 200 characters" }
                        ]}>
                        <Input onKeyDown={onKeyDown} />
                    </Form.Item>

                    {
                        props.section.exportInOriginalFormat &&
                        <Form.Item name="exportInOriginalFormat" valuePropName='checked'>
                            <Checkbox>Keep export in original format</Checkbox>
                        </Form.Item>
                    }
                </Form>
            </Spin>
        </Modal>
    )
}