import { Button, Card, Space, Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import { HasAnyRole, IsPricingAdmin, IsPricingMember } from "../../common/CommonFunctions";
import { Roles } from "../../common/Enums";
import { useAuth } from "../auth";
import { AutomationEfficiencyStats } from "./AutomationEfficiencyStats";
import { AutomationTrendStats } from "./AutomationTrendStats";
import { MyTaskStats } from "./MyTaskStats";
import { OpenQuotesStats } from "./OpenQuotesStats";
import { ProjectCompletedStats } from "./ProjectCompletedStats";
import { ProjectStatusOvertimeStats } from "./ProjectStatusOvertimeStats";
import { ProjectStatusStats } from "./ProjectStatusStats";
import { QuotesApproverStats } from "./QuotesApproverStats";
import { QuotesOwnerStats } from "./QuotesOwnerStats";
import { QuotesProcessedStats } from "./QuotesProcessedStats";
import { RecentProjects } from "./RecentProjects";
import { RecentQuotes } from "./RecentQuotes";

const DashboardTabs = {
    Projects: { name: "Projects", key: 'projects' },
    MyQuotes: { name: "My Quotes", key: 'my-quotes' },
    TeamQuotes: { name: "Team Quotes", key: 'team-quotes' }
}

const Dashboard: React.FC = () => {
    const { user_metadata } = useAuth();
    const hasProjectAccess = HasAnyRole(user_metadata?.roles ?? [], [Roles.CompanyAdmin, Roles.ProjectOwner, Roles.Collaborator]),
        isProjectAdmin = HasAnyRole(user_metadata?.roles ?? [], [Roles.CompanyAdmin, Roles.ProjectOwner]);
    const isPricingAdmin = IsPricingAdmin(user_metadata?.roles),
        isPricingMember = IsPricingMember(user_metadata?.roles);
    const hasPricingAccess = isPricingAdmin || isPricingMember;

    const [selectedTab, setSelectedTab] = useState<string | undefined>();

    const onTabChange = (activeKey: string) => {
        setSelectedTab(activeKey);
    }

    const visibleDashboardTabs = useMemo(() => {
        const tabs: TabsProps['items'] = [];

        if (hasProjectAccess)
            tabs.push({
                key: DashboardTabs.Projects.key,
                label: <Space>
                    <KTSVG path="media/icons/duotune/general/gen005.svg" className="svg-icon-2x" />
                    {DashboardTabs.Projects.name}
                </Space>,
                children: <ProjectTab />,
            });

        if (hasPricingAccess)
            tabs.push({
                key: DashboardTabs.MyQuotes.key,
                label: <Space>
                    <KTSVG path="media/icons/duotune/finance/fin010.svg" className="svg-icon-2x" />
                    {DashboardTabs.MyQuotes.name}
                </Space>,
                children: <PricingTab teamStats={false} />,
            });

        if (isPricingAdmin)
            tabs.push({
                key: DashboardTabs.TeamQuotes.key,
                label: <Space>
                    <KTSVG path="media/icons/duotune/graphs/gra005.svg" className="svg-icon-2hx" />
                    {DashboardTabs.TeamQuotes.name}
                </Space>,
                children: <PricingTab teamStats={true} />,
            });

        return tabs;
    }, [])

    const tabBarActions = useMemo(() => {
        if (selectedTab === DashboardTabs.Projects.key) {
            return <Space>
                {
                    isProjectAdmin &&
                    <Link to='/projects/add-project'>
                        <Button size="large" className="btn-gradient btn-gradient-blue">Create Project</Button>
                    </Link>
                }
                <Link to={'/projects/list'}>
                    <Button size="large" type="primary" ghost>View Projects</Button>
                </Link>
            </Space>
        }
        else if (selectedTab === DashboardTabs.MyQuotes.key) {
            return <Space>
                <Link to='/quotes/new-quote'>
                    <Button size="large" className="btn-gradient btn-gradient-blue">Create Quote</Button>
                </Link>
                <Link to='/quotes/list'>
                    <Button size="large" type="primary" ghost>View Quotes</Button>
                </Link>
            </Space>
        }
        else if (selectedTab === DashboardTabs.TeamQuotes.key) {
            return <Space>
                <Link to='/quotes/new-quote'>
                    <Button size="large" className="btn-gradient btn-gradient-blue">Create Quote</Button>
                </Link>
                <Link to='/quotes/list'>
                    <Button size="large" type="primary" ghost>View Quotes</Button>
                </Link>
            </Space>
        }
    }, [selectedTab])

    useEffect(() => {
        visibleDashboardTabs.length && setSelectedTab(visibleDashboardTabs[0].key)
    }, [])

    return (
        <Card size="small">
            <Tabs size="large"
                activeKey={selectedTab}
                items={visibleDashboardTabs}
                onChange={onTabChange}
                tabBarExtraContent={tabBarActions}
            />
        </Card>
    )
}

export { Dashboard };

const ProjectTab: React.FC = () => {
    return (
        <>
            <Row>
                <Col sm={12} md={6} className="mb-5">
                    <MyTaskStats />
                </Col>

                <Col sm={12} md={6} className="mb-5">
                    <RecentProjects />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectStatusStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <AutomationEfficiencyStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectStatusOvertimeStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <AutomationTrendStats />
                </Col>
                <Col sm={12} md={6} className="mb-5">
                    <ProjectCompletedStats />
                </Col>
            </Row>
        </>
    )
}

const PricingTab: React.FC<{ teamStats: boolean }> = (props) => {
    const { user_metadata } = useAuth();
    const IsAdmin = IsPricingAdmin(user_metadata?.roles);

    return (
        <>
            <Row>
                <Col sm={12} md={props.teamStats ? 12 : 6} className="mb-5">
                    <OpenQuotesStats teamStats={props.teamStats} />
                </Col>
                {
                    !props.teamStats &&
                    <Col sm={12} md={6} className="mb-5">
                        <RecentQuotes />
                    </Col>
                }
            </Row>

            {
                IsAdmin &&
                <Row>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesOwnerStats teamStats={props.teamStats} />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesOwnerStats isPriceChart={true} teamStats={props.teamStats} />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesApproverStats teamStats={props.teamStats} />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesApproverStats isPriceChart={true} teamStats={props.teamStats} />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesProcessedStats teamStats={props.teamStats} />
                    </Col>
                    <Col sm={12} md={6} className="mb-5">
                        <QuotesProcessedStats isPriceChart={true} teamStats={props.teamStats} />
                    </Col>
                </Row>
            }
        </>
    )
}