export enum Roles {
    SuperAdmin = 1,
    CompanyAdmin = 2,
    ProjectOwner = 3,
    Collaborator = 4,
    PricingMember = 5,
    PricingAdmin = 6
}

export enum QuestionUserRole {
    Owner = 1,
    Approver = 2,
    Assignee = 3,
    TeamMember = 4,
}

export enum ActionType {
    Insert = 'I',
    Update = 'U',
    Delete = 'D'
}

export enum StaticDataSets {
    Default = -1,
    Attachments = -2
}

export const DataSetType: Record<string, number> = {
    CompanyWebsite: 1,
    CompanyProfile: 2,
    CompanyInfo: 3,
    Products: 4,
    AdditionalDataSets: 5
}

export const DataSetContentType: Record<string, number> = {
    RichText: 1,
    WebUrl: 2,
    File: 3
}

export const CompanySetupSteps = [
    {
        key: DataSetType.CompanyProfile,
        initialSetup: true,
        postSetup: true,
        title: "Your Company",
        description: "Tell us about your company so that we can train the AI module specifically for you.",
    },
    {
        key: DataSetType.CompanyInfo,
        initialSetup: true,
        postSetup: true,
        title: "Company Information",
        description: "Share information about your company to train the AI. You can write it, load documents and share webpages with information. Share as much information as you can since it improve the quality of the generated responses."
    },
    {
        key: DataSetType.Products,
        initialSetup: true,
        postSetup: true,
        title: "Products and Services",
        shortName: "Product",
        description: "Share information about your products and services. You can write it, load documents and share webpages with information. Share as much information as you can since it improve the quality of the generated responses."
    },
    {
        key: DataSetType.AdditionalDataSets,
        initialSetup: false,
        postSetup: true,
        title: "Additional Datasets",
        shortName: "Dataset",
        description: "Add additional information to help you respond to RFPs.  Some examples of additional content are Security Assessments, Due Diligence Questionnaires, etc."
    },
    {
        key: 100,
        initialSetup: true,
        postSetup: false,
        title: "Complete"
    },
    {
        key: 100,
        initialSetup: false,
        postSetup: true,
        title: "Rebuild Index"
    }
]

export const RTKQueryTags = {
    UserSelectList: "UserSelectList",
    CustomerSelectList: "CustomerSelectList",
    ProjectList: "ProjectList",
    ProjectDetails: "ProjectDetails",
    DatasetSelectList: "DatasetSelectList",
    QuestionReviewList: "QuestionReviewList",
    QuestionList: "QuestionList",
    AnswerList: "AnswerList",
    CompanySetupConfig: "CompanySetupConfig",
    PricingInstructionSelectList: "PricingInstructionList",
}

export enum ResponseFormat {
    Short = 6,
    Custom = 8
}

export const DateFormats = {
    DateOnly: "MM/DD/YYYY"
}

export const QuestionFileStatus = {
    Pending: 1,
    InProgress: 2,
    UnderReview: 3,
    Completed: 4
}

export const QuestionFileStatusDetail = {
    [QuestionFileStatus.Pending]: {
        value: QuestionFileStatus.Pending,
        name: "Pending", color: "orange"
    },
    [QuestionFileStatus.InProgress]: {
        value: QuestionFileStatus.InProgress,
        name: "In-Progress", color: "gold"
    },
    [QuestionFileStatus.UnderReview]: {
        value: QuestionFileStatus.UnderReview,
        name: "In Review", color: "blue"
    },
    [QuestionFileStatus.Completed]: {
        value: QuestionFileStatus.Completed,
        name: "Completed", color: "green"
    }
}

export enum QuestionStatus {
    Unreviewed = 1,
    Unanswered = 2,
    Drafted = 3,
    Submitted = 4,
    RequestChanges = 5,
    Accepted = 6
}

export const QuestionStatusDetails = [
    {
        value: QuestionStatus.Drafted,
        name: "Drafted"
    },
    {
        value: QuestionStatus.Submitted,
        name: "Submitted"
    },
    {
        value: QuestionStatus.RequestChanges,
        name: "Requested Changes"
    },
    {
        value: QuestionStatus.Accepted,
        name: "Accepted"
    }
]

export enum AnswerJobStatus {
    Idle = 0,
    Processing = 1
}

export const CrudActions = {
    Inserted: 'I',
    Deleted: 'D',
    Updated: 'U'
}

export enum ProjectPermissions {
    FullAccess = 1,
    ReadonlyAccess = 2
}

export enum ProjectStatus {
    Open = 1,
    Submitted = 2,
    DidNotParticipate = 3,
    Won = 4,
    Lost = 5
}

export enum AnswerSourceType {
    NinjaGenerated = 1,
    LibraryGenerated = 2,
    Composed = 3
}

export const FactorTypes = {
    totp: { key: "totp", value: "Authenticator App" },
    sms: { key: "sms", value: "SMS" },
    email: { key: "email", value: "Email" }
}

export enum AddToLibraryStatus {
    Accepted = 1,
    Pending = 2,
    Rejected = 3
}

export enum DashboardStatsType {
    ProjectStatus = 'ProjectStatus',
    AutomationEfficiency = 'AutomationEfficiency',
    ProjectStatusOvertime = 'ProjectStatusOvertime',
    AutomationTrend = 'AutomationTrend',
    ProjectCompleted = 'ProjectCompleted',
    MyTasks = 'MyTasks',
    QuotesByOwner = 'QuotesByOwner',
    QuotesValueByOwner = 'QuotesValueByOwner',
    QuotesApproved = 'QuotesApproved',
    QuotesValueApproved = 'QuotesValueApproved',
    QuotesProcessed = 'QuotesProcessed',
    QuotesValueProcessed = 'QuotesValueProcessed',
    
    TeamQuotesByOwner = 'QuotesByOwner',
    TeamQuotesValueByOwner = 'QuotesValueByOwner',
    TeamQuotesApproved = 'QuotesApproved',
    TeamQuotesValueApproved = 'QuotesValueApproved',
    TeamQuotesProcessed = 'QuotesProcessed',
    TeamQuotesValueProcessed = 'QuotesValueProcessed'
}

export enum PricingRequestStatus {
    Drafted = 1,
    Submitted = 2,
    RequestChanges = 3,
    Final = 4
}

export const PricingRequestStatusDetails = {
    [PricingRequestStatus.Drafted]: {
        value: PricingRequestStatus.Drafted,
        name: "Draft"
    },
    [PricingRequestStatus.Submitted]: {
        value: PricingRequestStatus.Submitted,
        name: "Submitted for Approval"
    },
    [PricingRequestStatus.RequestChanges]: {
        value: PricingRequestStatus.RequestChanges,
        name: "Requested Changes"
    },
    [PricingRequestStatus.Final]: {
        value: PricingRequestStatus.Final,
        name: "Complete"
    }
}

export enum ExportTemplateType {
    Project = 1,
    PricingFinalPrice = 2
}

export enum FreshnessScoreType {
    Green = 1,
    Yellow = 2,
    Red = 3
}