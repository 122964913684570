import { Card, DatePicker, Spin } from "antd";
import dayjs from 'dayjs';
import _, { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { selectFilters, setFilters } from "../../../redux/DashboardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useApproverQuotesStatsMutation } from "../../../redux/rtkquery/DashboardAPI";
import { AntDRangePresets } from "../../common/Constants";
import { DashboardStatsType } from "../../common/Enums";
import { localeCurrency, localeNumber, ChartColors } from "../../common/CommonFunctions";

type SeriesType = {
  name: string,
  data: number[]
}

type Props = {
  teamStats: boolean,
  isPriceChart?: boolean
}

const QuotesApproverStats: React.FC<Props> = (props) => {
  const [options, setOptions] = useState<any>(null);
  const [series, setSeries] = useState<SeriesType[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<any>(null);
  const [triggerQuoteStats, quoteStatsResult] = useApproverQuotesStatsMutation();
  const dispatch = useAppDispatch();
  const filtersKey = props.teamStats ? (props.isPriceChart ? DashboardStatsType.TeamQuotesValueApproved : DashboardStatsType.TeamQuotesApproved) :
  (props.isPriceChart ? DashboardStatsType.QuotesValueApproved : DashboardStatsType.QuotesApproved);
  const filters = useAppSelector(state => selectFilters(state, filtersKey));

  const onRangeChange = (range: any) => {
    if (range) {
      let toDate: dayjs.Dayjs = range[1];
      range[1] = toDate.endOf('M');
    }

    setDateRange(range);
    setMonthCategories(range);

    dispatch(setFilters({
      type: filtersKey,
      filters: {
        range: [range[0].format("MM/DD/YYYY"), range[1].format("MM/DD/YYYY")]
      }
    }));
  }

  const setMonthCategories = (dateRange: dayjs.Dayjs[]) => {
    let _categories = [], dateRanges = _.cloneDeep(dateRange);

    while (dateRanges[0].endOf('M') <= dateRanges[1].endOf('M')) {
      _categories.push(dateRanges[0].format('MMM YY'));
      dateRanges[0] = dateRanges[0].add(1, "M");
    };

    setCategories(_categories);
    return _categories;
  }

  const loadStats = (dateRange: dayjs.Dayjs[]) => {
    triggerQuoteStats({
      fromDate: dateRange?.[0].format("MM/DD/YYYY") || null,
      toDate: dateRange?.[1].format("MM/DD/YYYY") || null,
      teamStats: props.teamStats
    });
  }

  const initTeamChart = (chartSeries: SeriesType[], chartCategories: string[]) => {

    setSeries(chartSeries);

    setOptions({
      colors: ChartColors,
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          barHeight: chartCategories.length <= 3 ? (20 * chartCategories.length) + '%' : '90%',
          horizontal: true,
          borderRadius: 10,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'last', // 'all', 'last'
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => {
          return props.isPriceChart ? localeCurrency(val) : localeNumber(val);
        },
        style: {
          fontSize: '12px',
          colors: ["#000"]
        }
      },
      xaxis: {
        categories: chartCategories,
        labels: {
          show: true,
          formatter: (val: number) => {
            return props.isPriceChart ? localeCurrency(val) : localeNumber(val);
          }
        }
      },
      tooltip: {
        y: {
          formatter: (val: number) => {
            return props.isPriceChart ? localeCurrency(val) : localeNumber(val);
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        horizontalAlign: 'center',
        showForSingleSeries: true,
        position: 'bottom'
      }
    })
  }

  const initUserChart = (chartSeries: number[], chartCategories: string[]) => {
    setSeries([
      {
        name: 'Quotes Approved',
        data: chartSeries
      }
    ]);

    setOptions({
      colors: ChartColors,
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: categories.length <= 3 ? (15 * categories.length) + '%' : '90%',
          borderRadius: 10,
          dataLabels: {
            position: 'top'
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val: number) => {
          return props.isPriceChart ? localeCurrency(val) : localeNumber(val);
        },
        style: {
          fontSize: '12px',
          colors: ["#000"]
        }
      },
      xaxis: {
        categories: chartCategories,
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        min: !props.isPriceChart ? 0 : undefined,
        max: (_.max(chartSeries) || 0) <= 3 ? 5 : undefined,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: (val: number) => {
            return props.isPriceChart ? localeCurrency(val) : localeNumber(parseInt(val.toFixed(0)));
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: false
      }
    })
  }

  useEffect(() => {
    let dateRange = AntDRangePresets?.[0].value;

    if (filters?.range) {
      dateRange = [dayjs(filters.range[0]), dayjs(filters.range[1])];
    }

    onRangeChange(dateRange);

  }, []);

  useEffect(() => {
    if (filters && !dateRange)
      return;
    loadStats(dateRange);
  }, [categories]);

  useEffect(() => {
    if (quoteStatsResult.requestId && !quoteStatsResult.isLoading &&
      quoteStatsResult.data && quoteStatsResult.data.success) {

      if (props.teamStats) {
        const chartSeries: SeriesType[] = [],
          statsData = _.orderBy(cloneDeep(quoteStatsResult.data.data), ["month", "year"]),
          allUserNames = new Set<string>(),
          allDates = new Set<string>();

        if (props.isPriceChart)
          _.remove(statsData, x => x.totalPrice <= 0);

        // Set Month display label
        _.forEach(statsData, x => {
          x.date = dayjs(`${x.year}-${x.month}-01`, 'YYYY-M-D').format("MMM YY");
          allUserNames.add(x.userName);
          allDates.add(x.date);
        });

        // Order all user names
        let _allUserNames = _.orderBy(Array.from(allUserNames));

        // Group data based on Date
        let dateStats = _.groupBy(statsData, x => x.date);

        _.forEach(Array.from(allDates), (date) => {
          let data = dateStats[date];

          chartSeries.push({
            name: date,
            data: _allUserNames.map(u => {
              let stat = data.find(x => x.userName === u);
              return (props.isPriceChart ? stat?.totalPrice : stat?.requestCount) || 0
            })
          })
        });

        initTeamChart(chartSeries, _allUserNames);
      }
      else {
        const chartSeries: number[] = [],
          categoriesDict: Record<string, number> = {};

        let statsData = cloneDeep(quoteStatsResult.data.data)

        _.forEach(statsData, x => {
          // Set Completion Month display label
          let completionOn = dayjs(`${x.year}-${x.month}-01`, 'YYYY-M-D').format("MMM YY");

          // Set data grouping based on submission month
          categoriesDict[completionOn] = props.isPriceChart ? x.totalPrice : x.requestCount;
        });

        categories.forEach(x => chartSeries.push(categoriesDict[x] ?? 0));

        initUserChart(chartSeries, categories);
      }
    }
  }, [quoteStatsResult]);

  return (
    <Card className="h-100">
      <Spin spinning={quoteStatsResult.isLoading}>

        <div className='d-flex align-items-center justify-content-between mb-5'>
          <span className='fs-3 fw-bold text-dark me-2 lh-1'>
            {
              props.isPriceChart ? 'Quotes Approved ($)' : 'Quotes Approved'
            }
          </span>

          <DatePicker.RangePicker
            style={{ maxWidth: '250px' }}
            presets={AntDRangePresets}
            picker="month"
            size="small"
            value={dateRange}
            onChange={onRangeChange}
          />
        </div>

        {
          options !== null &&
          <ReactApexChart options={options}
            series={series}
            type="bar" height={'300'} width={'100%'} />
        }
      </Spin>
    </Card>
  )
}

export { QuotesApproverStats };

